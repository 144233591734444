import { GoACircularProgress, GoAContainer,  GoAIconButton, GoATooltip } from "@abgov/react-components"
// import { getClaims, useAuthUser } from "../apis/auth";
import './Home.css'
import { hasResourceRole } from "../apis/tokenUtil";
import { useAuth } from "react-oidc-context";
import { appList } from "../apis/constants";

export const Home = () => {
  const auth = useAuth();
  const isAuthorized = () => {
    const res = appList.filter(item => hasResourceRole(item.resource as string, item.permission as string[], auth.user?.access_token as string)).length > 0;
    return res;
  }
  return (<>
    <div className="content">
      <h1>Alberta Wildfire Applications Portal</h1>
      {auth.isLoading && <GoACircularProgress visible={true} />}
      {!auth.isLoading && auth.isAuthenticated  &&
        <div className="grid">
          {appList.filter(item => hasResourceRole(item.resource as string, item.permission as string[], auth.user?.access_token as string))
            .map((item, index) => <div key={index} className="app-container">
              <section className="item-wrapper">
                <GoAContainer padding="compact" type="non-interactive" accent="thin">
                  <section className="tooltip-wrapper">
                    <GoATooltip content={item.toolTipText} position="bottom">
                      <GoAIconButton size="medium" icon="help-circle"></GoAIconButton>
                    </GoATooltip>
                  </section>
                  <section className="link-wrapper">
                    <img src={item.img}  width="24" height="24" title={item.label} alt={item.label}/>
                    {item.enableLink &&
                      <a target="_blank" rel="noopener noreferrer" href={item.url}>{item.label}</a>}
                    {!item.enableLink &&
                      <span>&nbsp;{item.label}</span>}
                  </section>
                </GoAContainer>
              </section>
            </div>
            )}
        </div>
      }
      {!auth.isLoading && auth.isAuthenticated && !isAuthorized() &&
        <NotAuthorized />
      }
    </div>
  </>)
}


const NotAuthorized = () => {
  return (
    <p>
      You are currently unable to access any applications in the portal because no user roles are assigned to you.
      For access, please contact one of the following: your supervisor, your Area's Regional Administrator,
      your Wildfire Management Branch contact, or Wildfire Applications Support via&nbsp;
      <a href="/#">wildfire.applications@gov.ab.ca</a> or +1 780-427-8100
    </p>
  );
}